// $body-bg: #000;
$theme-colors: (
  "primary": #005151,
  "secondary": #8BD3E6,
  "dark": #005151,
  "light": #F8F8F8,
);

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}


// Body changes
$body-bg: #EFDBB2;
// theme-color('light');
// #EFDBB2;
$body-color: theme-color("primary");
$border-radius: 0.5rem;

// Button
$btn-border-radius: 0.5rem;
$navbar-dark-toggler-border-color: theme-color("dark");
$navbar-light-toggler-border-color: theme-color("light");
$navbar-toggler-border-radius: 0rem;
// Card
$card-border-width: 0px;

// Form
$input-border-width: 0px;
$component-active-bg: theme-color();
$input-bg: theme-color('light');

// Table
$table-bg: white;

// $enable-responsive-font-sizes: true;

// import bootstrap theme
@import '~bootstrap/scss/bootstrap.scss';

/////////////* CSS Starts */////////////

:root {
  --dark-green: #005151;
  --light-brown: #EFDBB2;
  --light-blue: #8BD3E6;
  --dark-brown: #4F2C1D;
  width: 99%;
}

body {
  // padding-top: 70px;
  font-family: 'Objective', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.farmer-table th {
  position: sticky;
  top: 0;
  background: white;
  // z-index: 1;
  // box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.farmer-table {
  overflow: auto;
  max-height: 100vh;
  margin-top: 20px;
  border-radius: 0.5rem;
  display: grid; // shows on Safari
  border-collapse: collapse;
  // margin-right: auto;
  // margin-left: auto; 
  // margin-left: 20px;
  // margin-right: 20px;
  background-color: white;

  a:link {
    color: black !important;
    text-decoration: none !important;
    font-weight: bold !important;
  }

  a:visited {
    color: black !important;
    text-decoration: none !important;
    font-weight: bold !important;
  }

  a:active {
    color: black !important;
    text-decoration: none !important;
    font-weight: bold !important;
  }

  a:hover {
    color: var(--dark-green) !important;
    text-decoration: none !important;
    font-weight: bold !important;
  }

  // a:active {
  //     color: var(--dark-green);
  //   a:hover {
  //   }
  // }
}

@media (min-width: 992px) {
  .container.max-width-720 {
    max-width: 720px !important;
  }
}

@media (min-width: 1200px) {
  .container.max-width-720 {
    max-width: 720px !important;
  }
}

.sidebar {
  // position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vmax !important;
  height: -webkit-fill-available;
  z-index: 100;
  // padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  background-color: var(--dark-green);
  // #EFDBB2;

.topbar {
  padding: 0;
  background-color: var(--dark-green);
  height: 60px;
  position: fixed;
}

  a:active {
    font-weight: bold;
    color: var(--dark-green);
  }

  a:visited {
    font-weight: bold;
    color: white;
  }

  a:link {
    font-weight: bold;
    color: white;
  }

  .begin-home {
    top: 10px;
    position: fixed;
  }

  .end-logout {
    bottom: 10px;
    position: fixed;
    text-align: center;
    font-size: 14px;
  }
}


.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  color: #005151;
  font-size: 16px;
}

.react-datepicker__input-container input {
  padding: 10px;
  height: 56px;
  text-align: center;
}

.checkbox {
  $body-color: theme-color("primary");
}

.photo-column {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  border: none !important;
}

.unstyled-button {
  border: none !important;
  background: transparent;
  background-color: white;
  padding: 0;
  outline-style: none !important;
  outline: none !important;
  box-shadow: none !important;

}

.leaflet-popup-content-wrapper,
.leaflet-popup-content {
  -webkit-border-radius: 0.5rem !important;
  -moz-border-radius: 0.5rem !important;
  border-radius: 0.5rem !important;
}

.zero-vertical-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.react-datepicker-ignore-onclickoutside {
  text-align: 'center'
}

::-webkit-scrollbar {
  width: 0px;
}